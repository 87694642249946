<template>
  <div :class="{ 'disable-item': loading }">
    <CForm @submit.prevent="sendEmail">
      <div>
        <div class="form-row mb-3 table-info py-2 rounded">
          <label for="to" class="col-2 col-sm-1 col-form-label d-none d-sm-flex justify-content-center"><CIcon name="cil-bolt" height="20" /></label>
          <CCol cols="12" sm="11" class="d-flex flex-column">
            <ul class="checklist">
              <li class="form-check">
                <input class="form-check-input" type="checkbox" id="checkIsTask" v-model="isTask">
                <label class="form-check-label" for="checkIsTask">
                  Task?
                </label>
              </li>

              <li class="form-check" v-if="isTask">
                <input class="form-check-input" type="checkbox" id="checkAssignMyself" v-model="isMyTask">
                <label class="form-check-label" for="checkAssignMyself">
                  Assign myself the task
                </label>
              </li>
          </ul>

          </CCol>
        </div>

        <transition name="fade">
        <div class="form-row mb-3" v-if="!isMyTask">
          <label for="to" class="col-2 col-sm-1 col-form-label 	d-none d-sm-flex justify-content-center"><CIcon name="cil-at" height="20" /></label>
          <CCol cols="12" sm="11">
            <v-select
              label="first_name"
              v-model="to"
              :options="users"
              placeholder="Recipients"
            >
              <template v-slot:option="user">
                <span>{{ `${user.first_name} <${user.email}>` }}</span>
              </template>
            </v-select>
          </CCol>
        </div>
        </transition>

        <transition name="fade">
        <div class="form-row mb-3" v-if="!isMyTask">
          <label for="cc" class="col-2 col-sm-1 col-form-label 	d-none d-sm-flex justify-content-center"><CIcon name="cil-cc" height="20" /></label>
          <CCol cols="12" sm="11">
            <v-select
              label="first_name"
              v-model="cc"
              :options="users"
              placeholder="Type user name or email"
              multiple
            >
              <template v-slot:option="user">
                <span>{{ `${user.first_name} <${user.email}>` }}</span>
              </template>
            </v-select>
          </CCol>
        </div>
        </transition>

        <div class="form-row mb-3">
          <label for="cc" class="col-2 col-sm-1 col-form-label 	d-none d-sm-flex justify-content-center"></label>
          <CCol cols="12" sm="11">
            <CInput
              class="compose-input"
              placeholder="Subject"
              :value.sync="subject"
              :lazy="false"
              was-validated
              required
            />
          </CCol>
        </div>

        <div class="form-row mb-3">
          <label for="to" class="col-2 col-sm-1 col-form-label 	d-none d-sm-flex justify-content-center"><CIcon name="cil-restaurant" height="20" /></label>
          <CCol cols="12" sm="11">
            <v-select
              v-model="restaurant"
              :options="restaurants"
              placeholder="Select a restaurant.."
            />
          </CCol>
        </div>

        <div class="form-row mb-3">
          <label for="cc" class="col-2 col-sm-1 col-form-label 	d-none d-sm-flex justify-content-center"><CIcon name="cil-tags" height="20" /></label>
          <CCol cols="12" sm="11">
            <LabelDropdown :labels.sync="labels"></LabelDropdown>
          </CCol>
        </div>
      </div>

      <CRow>
        <CCol sm="11" class="ml-auto">
          <div class="form-group reply">
            <quill-editor
              :options="{ placeholder: 'Write your message here' }"
              v-model="reply"
            />
          </div>

          <div class="form-group d-flex">
            <CButton type="submit" class="mr-1" color="info" :disabled="loading">
              <CIcon name="cil-send" /> Send
            </CButton>

            <CButton
              class="mr-1 ml-auto"
              color="danger"
              variant="outline"
              v-c-tooltip="'Discard'"
              :to="{ name: 'Inbox' }"
              ><CIcon name="cil-trash"
            /></CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>

    <CElementCover :opacity="0.4" v-show="loading">
      <CSpinner size="5xl" grow color="primary" />
    </CElementCover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import LabelDropdown from "./LabelDropdown";

export default {
  name: "Compose",
  components: {
    LabelDropdown,
    quillEditor,
  },
  data() {
    return {
      isTask: true,
      isMyTask: false,
      to: [],
      cc: [],
      users: [],
      restaurants: [],

      restaurant: null,
      reply: "",
      subject: "",
      labels: ["waiting"],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["errors"]),
  },
  watch: {
    isTask: function (val) {
      if (val === false) {
        this.isMyTask = false;
      }
    },
    isMyTask: function (val) {
      if (val) {
        this.to = {
          id: this.authUser.user.id,
          email: this.authUser.user.email,
        };
        this.cc = [];
      }
    },
  },

  async mounted() {
    await Promise.all([this.getAdminUsers(), this.getRestaurants()]);
  },

  methods: {
    async getAdminUsers() {
      await this.$axios
        .get(this.$backend.USERS.GET_ADMIN_USERS, {
          params: { fields: "email", "exclude-user": "current" },
        })
        .then((response) => {
          this.users = response.data;
        });
    },
    async getRestaurants() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.restaurants = response.data;
        });
    },

    async sendEmail() {
      this.loading = true;
      await this.$axios
        .post(this.$backend.CRM_EMAILS.SEND_EMAIL, {
          to: this.to,
          cc: this.cc,
          subject: this.subject,
          reply: this.reply,
          labels: this.labels,
          'restaurant-id': (this.restaurant && this.restaurant.value) || null,
          'is-task': this.isTask
        })
        .then(() => {
          const routeName = this.isTask ? 'Task List' : 'Sent';
          const message = this.isTask ? (this.isMyTask ? "Task assigned." : 'Task assigned and email sent.') : 'Email sent.';

          this.$toast.success(message);
          this.$router.push({ name: routeName });
        })
        .catch(() => {
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.compose-input > input {
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 0.25rem;
}

.disable-item {
  pointer-events: none;
  opacity: 0.6;
}

.reply {
  min-height: 320px;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.reply .ql-editor {
  min-height: 240px;
}

.table-info ul.checklist {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.table-info ul.checklist li {
  line-height: 1.75;
  font-size: 1.25em;
}
.table-info input.form-check-input {
  width: 1.25rem;
  height: 1.25rem;
}
.table-info .form-check-label {
  padding-left: 0.5rem;
}
</style>
